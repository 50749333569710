import React, { useEffect, useState } from 'react'

import { PageLoading } from '../../common/Icons/Loading/pageLoading'
import { Country, State } from 'country-state-city'
import { ViewDateFormat } from '../../utils/dateFormat'
import {
  ArrowDown,
  DotIcon,
  EmailIcon,
  FailedIcon,
  LocationIcon,
  PhoneIcon,
  SuccessIcon,
  UserIcon,
} from '../../common/Icons'
import logo from '../../assets/logo.png'
import ProfileInfo, { Information } from '../../common/Information/profileInfo'
import { useNavigate, useParams } from 'react-router-dom'
import default_user from '../../assets/user.png'
import ROUTES_URL from '../../constant/routes'

function ProfileDetails({ data }) {
  const [countryOptions, setCountryOptions] = useState([])
  const [stateOptions, setStateOptions] = useState([])
  const [open, setIsOpen] = useState(false)

  useEffect(() => {
    const countryData = Country.getAllCountries().map((country) => ({
      value: country.isoCode,
      name: country.name,
    }))
    setCountryOptions(countryData)
    const stateData = State.getStatesOfCountry(
      data?.basicDetails?.current_country,
    ).map((state) => ({
      value: state.isoCode,
      name: state.name,
    }))
    setStateOptions(stateData)
  }, [data?.basicDetails?.current_country])

  const current_country = countryOptions.find(
    (country) => country.value === data?.basicDetails?.current_country,
  )
  const state = stateOptions.find(
    (state) => state.value === data?.basicDetails?.current_state,
  )

  let navigate = useNavigate()

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev)
  }

  const profile = `${process.env.REACT_APP_FILE_URL}/${data?.basicDetails?.profile_pic}`
  const { id } = useParams()
  const localStorageData = localStorage.getItem('user')
  const convertToStringify = JSON.parse(localStorageData)
  const [isValidUser, setValidUser] = useState(false)

  useEffect(() => {
    if (id === convertToStringify?.id) {
      setValidUser(true)
    } else {
      setValidUser(false)
    }
  }, [convertToStringify?.id, id])

  return (
    <div className="">
      {data ? (
        <div>
          <div className="bg-white px-10 flex justify-between items-center">
            <div className="flex justify-between items-center">
              <div>
                <img
                  src={logo}
                  alt="zeroed"
                  className="lg:h-16 h-14 lg:w-16 w-14"
                />
              </div>
            </div>
            {isValidUser && (
              <div className="relative cursor-pointer">
                <div onClick={toggleMenu}>
                  <div className="flex justify-center items-center gap-x-4 border border-text-border py-2 px-4 rounded-lg">
                    <img
                      src={profile ? profile : default_user}
                      alt="profile"
                      className="bg-gray-400 rounded-full border h-8 w-8 flex items-center justify-center cursor-pointer"
                    />
                    <ArrowDown />
                  </div>
                </div>
                {isMenuOpen && (
                  <div className="absolute right-0 mt-2 bg-white shadow-lg rounded-md z-10">
                    <div className="p-2 px-5">
                      {profile !== null && (
                        <button
                          onClick={() => {
                            toggleMenu()
                            navigate(`${ROUTES_URL.HOME}`)
                          }}
                        >
                          <div className="flex justify-between items-center gap-x-3">
                            <div className="w-10">
                              <UserIcon />
                            </div>
                            <span className="whitespace-nowrap">
                              Edit Profile
                            </span>
                          </div>
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          {/* #287996 */}
          <div className="bg-grayLight md:px-10 px-2">
            <div className="flex lg:flex-row flex-col gap-x-6 pb-4">
              <div className="lg:w-[70%] w-full">
                <div className="pt-24"></div>
                <div className="bg-gradient-to-r from-[#287996] via-[#43bbe8] to-[#287996] h-auto py-4 w-full bg-cover bg-no-repeat rounded-lg">
                  <div className="flex flex-col justify-between mx-5">
                    <div className="flex gap-x-4">
                      <img
                        src={`${process.env.REACT_APP_FILE_URL}/${data?.basicDetails?.profile_pic}`}
                        alt="user-avatar-image"
                        className="border-[6px] border-solid border-grayLight rounded-md h-[106px] w-[106px] -mt-20"
                      />
                      <div className="ml-1 text-white font-bold  xl:text-2xl text-base capitalize">
                        {data?.basicDetails?.firstname +
                          ' ' +
                          data?.basicDetails?.lastname}
                      </div>
                    </div>
                    <div className="flex flex-wrap gap-x-4 mt-1 pb-2 ml-[128px]">
                      <div className="flex items-center justify-center gap-x-1 text-sm font-semibold text-white">
                        <EmailIcon /> {data?.basicDetails?.contact_email_id}
                      </div>
                      <div className="flex items-center justify-center gap-x-1 text-sm font-semibold text-white">
                        <PhoneIcon /> {data?.basicDetails?.contact_no}
                      </div>
                      <div className="flex items-center justify-center gap-x-1 text-sm font-semibold text-white">
                        <LocationIcon /> {data?.basicDetails?.current_city},{' '}
                        {state?.name}, {current_country?.name}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="lg:h-[calc(100vh-272px)] h-full overflow-y-auto">
                  {/* Basic Details */}
                  <ProfileInfo
                    title="Basic Information"
                    icon={true}
                    onClick={() => setIsOpen(!open)}
                    open={open}
                  >
                    <Information
                      name="Full Name"
                      value={
                        data?.basicDetails?.firstname +
                        ' ' +
                        data?.basicDetails?.lastname
                      }
                    />
                    <Information
                      name="Date Of Birth"
                      value={<ViewDateFormat date={data?.basicDetails?.dob} />}
                    />
                    <Information
                      name="Gender"
                      value={data?.basicDetails?.gender}
                    />
                    <Information
                      name="Nationality"
                      value={data?.basicDetails?.nationality}
                    />
                    <Information name="State" value={state?.name} />
                    <Information
                      name="City"
                      value={data?.basicDetails?.current_city}
                    />
                    <Information
                      name="Job Preferred location"
                      value={data?.basicDetails?.job_preferred_location}
                    />
                  </ProfileInfo>

                  {/* Skills */}
                  <ProfileInfo title="Skills" open={true}>
                    {/* Skill-1 */}
                    <div className="">
                      <div>
                        <div className="flex flex-col flex-wrap gap-1">
                          <div>
                            {data?.skills?.core_skill && (
                              <span className="flex flex-wrap text-base font-medium capitalize">
                                {data.skills.core_skill.trim()}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="bg-primary-100 py-4 px-4 mt-2">
                        <div className="flex gap-1 flex-wrap">
                          {data?.skills?.sub_skills?.[0] ? (
                            data?.skills?.sub_skills?.[0]
                              .split(',')
                              .map((skill, i, arr) => {
                                return (
                                  <div key={i} className="flex items-center">
                                    <div className="2xl:text-base md:text-sm text-xs text-primary capitalize">
                                      {skill.trim()}
                                    </div>
                                    {i < arr.length - 1 && (
                                      <div className="xl:mx-5 mx-2">
                                        <div className="h-5 w-[1px] bg-primary"></div>
                                      </div>
                                    )}
                                  </div>
                                )
                              })
                          ) : (
                            <div className="text-gray-500 capitalize">
                              Sub skills
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* Skill-2 */}
                    <div className="mt-4">
                      <div>
                        <div className="flex flex-col flex-wrap gap-1">
                          <div>
                            {data?.skills?.core_skill_second && (
                              <span className="text-base font-medium capitalize">
                                {data.skills.core_skill_second.trim()}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="bg-primary-100 py-4 px-4 mt-2">
                        <div className="flex gap-1 flex-wrap">
                          {data?.skills?.sub_skills_second?.[0] &&
                            data?.skills?.sub_skills_second?.[0]
                              .split(',')
                              .map((skill, i, arr) => {
                                return (
                                  <div
                                    key={i}
                                    className="flex flex-wrap items-center"
                                  >
                                    <div className="2xl:text-base md:text-sm text-xs text-primary capitalize">
                                      {skill.trim()}
                                    </div>
                                    {i < arr.length - 1 && (
                                      <div className="xl:mx-5 mx-2">
                                        <div className="h-5 w-[1px] bg-primary"></div>
                                      </div>
                                    )}
                                  </div>
                                )
                              })}
                        </div>
                      </div>
                    </div>
                    {/* Skill-3 */}
                    <div className="mt-4">
                      <div>
                        <div className="flex flex-col flex-wrap gap-1">
                          <div>
                            {data?.skills?.core_skill_third && (
                              <span className="text-base font-medium capitalize">
                                {data.skills.core_skill_third.trim()}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="bg-primary-100 py-4 px-4 mt-2">
                        <div className="flex gap-1 flex-wrap">
                          {data?.skills?.sub_skills_third?.[0] &&
                            data?.skills?.sub_skills_third?.[0]
                              .split(',')
                              .map((skill, i, arr) => {
                                return (
                                  <div
                                    key={i}
                                    className="flex flex-wrap items-center text-primary capitalize"
                                  >
                                    <div className="2xl:text-base md:text-sm text-xs">
                                      {skill.trim()}
                                    </div>
                                    {i < arr.length - 1 && (
                                      <div className="xl:mx-5 mx-2">
                                        <div className="h-5 w-[1px] bg-primary"></div>
                                      </div>
                                    )}
                                  </div>
                                )
                              })}
                        </div>
                      </div>
                    </div>
                  </ProfileInfo>

                  {/* Work Experience */}
                  <ProfileInfo title="Work Experience" open={true}>
                    <div className="flex gap-x-1">
                      <div>
                        <div>
                          {data?.workExperience.map((ex, i) => {
                            const country = countryOptions.find(
                              (country) =>
                                country.value === ex?.work_experience_country,
                            )
                            return (
                              <div key={i} className="w-full flex gap-x-1">
                                <div className="flex flex-col justify-center items-center">
                                  <div className="rounded-full w-4 h-4 bg-primary"></div>
                                  <div className="h-full w-[2px] bg-[#D7D9DE]"></div>
                                </div>
                                <div className="pb-4 pt-2">
                                  <div className="flex items-center gap-x-2 mt-2">
                                    <div className="xl:text-lg text-sm font-semibold capitalize text-primary">
                                      {ex.work_experience_company_name}
                                    </div>
                                    {ex?.isVerify ? (
                                      <div className="flex items-center gap-x-1">
                                        <div className="p-1 bg-green-700 rounded-full h-5 w-5 flex justify-center items-center">
                                          <SuccessIcon />
                                        </div>
                                        <div className='text-xs'>
                                          Verify by {ex.verificationName} (
                                          {ex.verificationDesignation})
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="p-1 bg-red-700 rounded-full h-5 w-5 flex justify-center items-center">
                                        <FailedIcon />
                                      </div>
                                    )}
                                  </div>
                                  <div className="xl:text-base text-xs font-semibold capitalize mt-2 text-primary">
                                    {ex?.work_experience_job_title}
                                  </div>
                                  <div className="xl:text-base text-xs font-semibold capitalize mt-2 text-primary">
                                    {ex?.work_experience_industry}
                                  </div>
                                  <div className="xl:text-base text-xs font-semibold capitalize mt-1 text-primary">
                                    {ex?.work_experience_sub_industry}
                                  </div>
                                  <div className="flex items-center gap-x-1 mt-1">
                                    <div className="flex items-center">
                                      <LocationIcon color={'#0095C1'} />{' '}
                                    </div>
                                    <div className="xl:text-base text-xs font-semibold capitalize text-primary">
                                      {ex?.work_experience_country}
                                    </div>
                                  </div>
                                  <div className="flex items-center gap-x-1 mt-2 text-primary">
                                    <div className="xl:text-base text-xs font-semibold capitalize">
                                      <ViewDateFormat
                                        date={ex?.experience_start_date}
                                      />
                                    </div>
                                    <div className="text-primary">-</div>
                                    <div className="xl:text-base text-xs font-semibold capitalize">
                                      {ex?.experience_end_date ? (
                                        <ViewDateFormat
                                          date={ex?.experience_end_date}
                                        />
                                      ) : (
                                        'Currently working here'
                                      )}
                                    </div>
                                  </div>

                                  <div className="gap-x-2">
                                    <div className="xl:text-lg text-sm font-semibold capitalize text-primary">
                                      Accomplishments
                                    </div>
                                    {ex.accomplishments_id
                                      ?.accomplishment_1 && (
                                      <div className="xl:text-base text-xs font-semibold mt-2 flex items-center capitalize">
                                        <DotIcon />
                                        {
                                          ex.accomplishments_id
                                            ?.accomplishment_1
                                        }
                                      </div>
                                    )}
                                    {ex.accomplishments_id
                                      ?.accomplishment_2 && (
                                      <div className="xl:text-base text-xs font-semibold mt-2 flex items-center capitalize">
                                        <DotIcon />
                                        {
                                          ex.accomplishments_id
                                            ?.accomplishment_2
                                        }
                                      </div>
                                    )}
                                    {ex.accomplishments_id
                                      ?.accomplishment_3 && (
                                      <div className="xl:text-base text-xs font-semibold mt-2 flex items-center capitalize">
                                        <DotIcon />
                                        {
                                          ex.accomplishments_id
                                            ?.accomplishment_3
                                        }
                                      </div>
                                    )}
                                    {ex.accomplishments_id
                                      ?.accomplishment_4 && (
                                      <div className="xl:text-base text-xs font-semibold mt-2 flex items-center capitalize">
                                        <DotIcon />
                                        {
                                          ex.accomplishments_id
                                            ?.accomplishment_4
                                        }
                                      </div>
                                    )}
                                  </div>

                                  <div className="xl:text-lg text-sm font-semibold capitalize text-primary mt-1">
                                    Feedback
                                  </div>
                                  <div className='xl:text-base text-xs font-semibold capitalize mt-2'>{ex.verificationFeedback}</div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </ProfileInfo>

                  {/* Global Education */}
                  <div>
                    <ProfileInfo title="Global Education" open={true}>
                      <div className="xl:text-base text-xs font-semibold text-primary capitalize">
                        {data?.internationalEducation?.level_of_education}
                      </div>
                      <div className="xl:text-base text-xs font-semibold capitalize mt-2">
                        {data?.internationalEducation?.field_of_study}
                      </div>
                      <div className="xl:text-base text-xs font-semibold capitalize mt-[2px]">
                        {data?.internationalEducation?.year_of_graduation}
                      </div>
                    </ProfileInfo>
                  </div>
                  {data?.canadianEducation?.isCanadianEducation && (
                    <div>
                      {/* Canadian Education */}
                      <ProfileInfo title="Canadian Education" open={true}>
                        <div className="xl:text-base text-xs font-semibold text-primary capitalize">
                          {data?.canadianEducation?.university}
                        </div>
                        <div className="xl:text-base text-xs font-semibold capitalize mt-2">
                          {data?.canadianEducation?.city}
                        </div>
                        <div className="xl:text-base text-xs font-semibold capitalize">
                          {data?.canadianEducation?.level_of_education_canadian}
                        </div>
                        <div className="xl:text-base text-xs font-semibold capitalize">
                          {data?.canadianEducation?.field_of_study_canadian}
                        </div>
                        <div className="xl:text-base text-xs font-semibold capitalize">
                          {data?.canadianEducation?.year_of_completion}
                        </div>
                        <div className="xl:text-base text-xs font-semibold capitalize">
                          {data?.canadianEducation?.gpa}(GPA)
                        </div>
                      </ProfileInfo>
                    </div>
                  )}

                  {/* Career Goals */}
                  <ProfileInfo title="Career Goals" open={true}>
                    <Information
                      name="Industry"
                      value={data?.careerGoal?.career_industry}
                    />
                    <Information
                      name="Field"
                      value={data?.careerGoal?.career_field}
                    />
                    <Information
                      name="NOC"
                      value={data?.careerGoal?.noc_number}
                    />
                  </ProfileInfo>
                </div>
              </div>

              {/* Video */}
              <div className="flex lg:w-[30%] w-full lg:max-h-[calc(100vh-272px)] h-full lg:mt-16 mt-5 bg-grayLight xl:p-4 p-1">
                <div className="relative w-full h-full mt-4">
                  <video
                    controls
                    className="min-h-[calc(100vh-272px)] w-full h-full rounded-lg"
                    style={{ aspectRatio: '16 / 9' }} // Set the aspect ratio to maintain a rectangle
                  >
                    <source
                      src={`${process.env.REACT_APP_FILE_URL}/${data?.basicDetails?.video}`}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <PageLoading />
      )}
    </div>
  )
}

export default ProfileDetails
